import React, {useRef, useEffect, useContext} from 'react';
import { Heading, Text } from "@chakra-ui/core"
import Img from "gatsby-image";
import { Link, useStaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '../components/layout';
import AppCtx from '../context/App_Ctx';


const Home = React.forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query {
      homeMeta: wpPage(title: {eq: "home-data"}) {
        id
        slug
        title
        pageMeta {
          bannertitle
          bannersubtitle
          ourbrotherhood
          bannerImage {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sinatra: animationImageOne {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sinatraBrother: animationImageTwo {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          couchBg: animationImageFinal {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          finalBanner {
            urlLink 
            sectionHeader
          }
        }
      }
    }
  `)

  const { scrollTop } = useContext(AppCtx);

  const animationContainer = useRef(null);


  let wH = typeof window !== `undefined` ? window.innerHeight : null

  useEffect(() => {
    wH = window.innerHeight;
  }, [ wH ])

  useEffect(() => {  
    //animation logic
    if(animationContainer && animationContainer.current){
      let yPos = animationContainer.current.getBoundingClientRect().top;
      const imgContainer = animationContainer.current.childNodes[0];
      const mainImg = imgContainer.childNodes[0];

      const windowWi = window.innerWidth;
      
      if (windowWi < 900){
        return
      }
      
      if(yPos <= 0){
        // fade in when couch element is half way in viewport
        let couchEnd = wH * 3 - (wH / 2); 
        let couchTrigger = (wH * 3);

        let absYPos = Math.abs(yPos);
        if(absYPos >= couchTrigger){
          mainImg.style.transform = 'scale(0.3) translate(20%, -40%)';
          mainImg.style.transformOrigin = 'left center';
          mainImg.style.transition = 'transform 0.3s, border-color 0.3s'
          imgContainer.style.position = 'absolute';
          imgContainer.style.top = 'unset';
          imgContainer.style.bottom = '0px';
          imgContainer.style.width = '100%';
          imgContainer.style.height = wH + 'px';

          animationContainer.current.childNodes[2].classList.add('show');
          mainImg.classList.add('framed');
        }if(absYPos >= couchEnd){
          
          if(absYPos < couchTrigger) {
            mainImg.style.transition = 'transform cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s, border-color 0.3s'
            imgContainer.style.position = 'fixed';
            // imgContainer.style.position = 'absolute';
            imgContainer.style.top = 'unset';
            imgContainer.style.bottom = '0px';
            imgContainer.style.width = '100%';
            imgContainer.style.height = wH + 'px';
            animationContainer.current.childNodes[2].classList.remove('show');
            mainImg.classList.remove('framed');
          }
          mainImg.style.transform = 'scale(0.3) translate(20%, -40%)';

        } else{
          animationContainer.current.childNodes[2].classList.remove('show');
          mainImg.classList.remove('framed');
          imgContainer.style.position = 'fixed';
          imgContainer.style.width = '100%';
          imgContainer.style.height = wH + 'px';

          let percent = (-yPos / couchEnd) * 100;
          let scaleTransition = 1 - ((percent / 100) * 0.7);
          let translateX = 0 + ((percent / 100) * 20);
          let translateY = 0 - ((percent / 100) * 40);

          mainImg.style.transformOrigin = 'left center';
          mainImg.style.transform = `scale(${scaleTransition})`
          + ` translate(${translateX + "%"}, ${translateY + "%"})`;
        }
      }else{
        mainImg.style.transform = 'scale(1) translate(0%, 0%)';
        imgContainer.style.position = 'absolute';
        imgContainer.style.top = '0px';
      }
    }
  }, [ animationContainer, scrollTop ]);
    
  const homeMeta = data.homeMeta.pageMeta;

  
  return (
    <Layout >
    <section className="page-container" >
      <div className="home-banner stn-banner">
        <Img fluid={homeMeta.bannerImage.localFile.childImageSharp.fluid} alt="home-banner"/>
        <div className="banner-text-wrap">
         <Heading as="h1" dangerouslySetInnerHTML={{ __html: homeMeta.bannertitle }} />
         <Heading as="h3" dangerouslySetInnerHTML={{ __html: homeMeta.bannersubtitle }} />
        </div>
      </div>
      
      <div className="title-txt-btn light-bg purple-lines">
        <h2 className="bottom-border">Our Brotherhood</h2>

        <Text dangerouslySetInnerHTML={{ __html: homeMeta.ourbrotherhood}}/>

        <Link to="/our-brotherhood" className="link-btn">Learn More<FontAwesomeIcon icon={['fas','angle-right']}/><FontAwesomeIcon icon={['fas','angle-right']}/><FontAwesomeIcon icon={['fas','angle-right']}/></Link>

        <div className="line-one deep-pruple-bg"></div>
        <div className="line-two purple-bg"></div>
      </div>

      <div className="animation-container" data-height="6-x window height" id="animation-container" style={{height: wH * 4}}   
      ref={animationContainer} 
     >
        <div>
          <Img imgStyle={{height: wH}} fluid={homeMeta.sinatra.localFile.childImageSharp.fluid} alt="sinatra"/>
        </div>

        <div className="mid-ani-img">
          <Img fluid={homeMeta.sinatraBrother.localFile.childImageSharp.fluid}/>
        </div>
        <div style={{height: wH}} className="final-frame-cta">

          <div className="cta title-txt-btn">
            <Heading as="h2" dangerouslySetInnerHTML={{ __html: homeMeta.finalBanner.sectionHeader }}/>
            <Link to={homeMeta.finalBanner.urlLink} className="link-btn">Rush Alpha Phi Delta<FontAwesomeIcon icon={['fas','angle-right']}/><FontAwesomeIcon icon={['fas','angle-right']}/><FontAwesomeIcon icon={['fas','angle-right']}/></Link>
          </div>
        </div>
      </div>
    </section>
    </Layout>
  )
});

export default Home;